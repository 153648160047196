export const environment = {
    production: true,
    messageLogin: false,
    isTcurvas: true,
    user: "YWRtaW4=",
    password: "MTIz",
    // urlApi: 'http://5.83.76.12:8283/ApiOvIberelectrica/',
    // urlCch: 'http://5.83.76.12:8283/ApiOvIberelectricaTM/',
    // urlApi: "https://apiovislaluz.portalswitching.com/APIOV/",
    // urlCch: "https://apiovislaluz.portalswitching.com/APIOVTM/",
    urlApi: "https://apiovislaluz.portalswitching.com/APIOV/",
    urlCch: "https://apiovislaluz.portalswitching.com/APIOVTM/",
    cryptoKey: "dvgYax3fTkgtMWh6vgk3yQXMtHOfX4Q=",
    cryptoIV: "f5GqxBGkpw7fzQ==",
    companyName: "ISLA LUZ DE CANARIAS, S.L.U.",
    companyAddress: "",
    companyEmail: "",
    companyPhone: "",
    urlAvisoLegal: "",
    urlCookies: "",
    urlUso: "",
    urlPoliticaPrivacidad: "",
    UserAccess: "DNI/CIF/NIF/EMAIL",
    TextoInicioSesion: "INICIO DE SESIÓN",
    HabilitarTPV: false,
    iconoresumennuevalectura: "fab fa-searchengin fa-4x",
    iconoresumendatospago: "far fa-credit-card-blank fa-4x",
    iconoresumenconsulta: "far fa-exclamation-square fa-4x",
    alinearmenusuperior: "izquierda", // derecha izquierda o centro
    MostrarConsumosPorHora: true,
    idiomas: false,
    email: "", // email al que llegaran todos los formularios de la oficina virtual
    comparativa: true, //Gráfico de resumen comparativa importes facturas
    historico: false, //Gráfico de resumen histórico importes facturas
    MostrarFacturaExcel: false,
    MostrarPerfilFacturacion: false,
    DisabledBotonModificarDatos: false, //Botón modificardatos
    DisabledBotonRealizarConsulta: false, //Botón realizar consulta
    DisabledBotonNuevaLectura: false, //Botón realizar nueva lectura
    MostrarTarifaGrupo: false,
    RegistrarAceptacionDeUso: false,
    VersionCondicionesUso: "V1",
    MostrarPreciosContrato: false,
    MostrarBotonFacturasImpagadas: false,
    MostrarPerfilContratoSoloUnaPotenciaDHA: true,
    TiempoEsperaGraficos: "500",
    agrupacontratoscups: false,
    MostrarDescargaMasivaFacturasPorCups: false,
    internalApiUrl: "",
    mesesgraficocch: 1,
    maxCUPSgraficoCCH: 150,
    MostrarBotonModificiacionDatosPerfil: true,
    MostrarDeuda: true,
    PrefijoNumeracionFacturas: "",
    PrefijoNumeracionContratos: "",
    GraficoConecta2: false,
    MostrarReactivaComparativaConsumos: true,
    OcultaTipoContacto1: "",
    UltimasFacturasResumen: null,
    CambiaTituloCChGraph: false,
    cchWitchCups: false,
    DialogFacturasPendientePago: false,
    DobleGraficoConsumosPorHora: true,
    mostrarFechaVencimientoFactura: true,
    mostrarLimitePagoFactura: false,
    _URL_: "",
    messageRecuperarPass: false,
    MostrarAdministradorListadoContratoFechaAlta: true,
    MostrarAdministradorListadoContratoFechaVto: true,
    MostrarAdministradorListadoContratoFechaBaja: true,
    MostrarAdministradorListadoContratoEntorno: true,
    MostrarListadoContratoDireccionSuministro: false,
    MostrarConsumosPorHoraClientes: true,
    MostrarPerfilContratoColectivo: true,
    UnirPerfilDatosEnvioYContacto: false,
    MostrarDatosContratoEnPerfil: false,
    marca: "",
    ApiCallContratosListaElekluz: false,
    defaultLang: "es",
    OcultarFacturasConecta2DeAntesDel31x11x19: false,
    MostrarDescargaMasivaFacturasBotonExcel: false,
    MostrarDescargaMasivaFacturasFiltroCif: false,
    MostrarDescargaMasivaFacturasFiltroImporte: false,
    MostrarDescargaMasivaFacturasFiltroTarifa: false,
    MostrarListadoContratoDenominacionCliente: false,
    MostrarResumenGraficosDeMultipuntoAAdministradorFincas: false,
    MostrarListadoContratoBotonGraficos: false,
    CambiarTitularElekluz: false,
    BotonElekluz: false,
    OcultarContactosEleia: false,
    SimplificacionSerieFactura: false,
    urlPagininaInicial: "",
    EleiaNombreAdministradorFincas: false,
    multiapi: false,
    peticionAPIs: [],
    MensajeCCHConExplicacion: true,
    MostrarRefCatastral: false,
    MostrarCierreSesion: true,
    DesarrolloCatgas: false,
    logindirectoenB64: true,
    MostrarCambioPass: true,
    eneluzEspec: false,
    DesarrolloBonarea: false,
    TextoLegal: "",
  ObligarCambioContrasena: true,
    primerLogin: false,
    redirigirPrimerLogin: false,
    mensajeCambioPasswordELeia: false,
  };
  